import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/staff/GetMenus\\b'))
    .reply(() => [200, GetMenus])
  mock.onAny(new RegExp('/staff/GetProfile\\b'))
    .reply(() => [200, GetProfile])
  mock.onAny(new RegExp('/staff/GetPermissions\\b'))
    .reply(() => [200, GetPermissions])
  mock.onAny(new RegExp('/staff/ChangePassword\\b'))
    .reply(() => [200, ChangePassword])
  mock.onAny(new RegExp('/staff/EditProfile\\b'))
    .reply(() => [200, EditProfile])

  mock.onAny(new RegExp('/staffmanage/GetStaffList\\b'))
    .reply(() => [200, GetStaffList])
  mock.onAny(new RegExp('/staffmanage/GetStaff\\b'))
    .reply(() => [200, GetStaff])
  mock.onAny(new RegExp('/staffmanage/AddStaff\\b'))
    .reply(() => [200, AddStaff])
  mock.onAny(new RegExp('/staffmanage/UpdateStaff\\b'))
    .reply(() => [200, UpdateStaff])
  mock.onAny(new RegExp('/staffmanage/DeleteStaff\\b'))
    .reply(() => [200, DeleteStaff])
  mock.onAny(new RegExp('/staffmanage/EditStaffPassword\\b'))
    .reply(() => [200, EditStaffPassword])
  mock.onAny(new RegExp('/StaffNfc/GetNfcList\\b'))
    .reply(() => [200, GetNfcList])
})

const GetMenus = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "menus": [
      {
        "code": "Com_Menu",
        "title": "公司",
        "subs": []
      },
      {
        "code": "Fac_Menu",
        "title": "院舍",
        "subs": []
      },
      {
        "code": "Bed_Menu",
        "title": "床位",
        "subs": [
          {
            "code": "Bed_Type_Menu",
            "title": "床位類型",
            "subs": null
          },
          {
            "code": "Bed_Floor_Menu",
            "title": "樓層與房間",
            "subs": null
          },
          {
            "code": "Bed_Bed_Menu",
            "title": "床位",
            "subs": null
          }
        ]
      },
      {
        "code": "Sta_Menu",
        "title": "職員",
        "subs": []
      },
      {
        "code": "Mem_Menu",
        "title": "會員",
        "subs": []
      },
      {
        "code": "Gro_Menu",
        "title": "組別",
        "subs": []
      },
      {
        "code": "Role_Menu",
        "title": "職位",
        "subs": []
      },
      {
        "code": "Kiosk_Menu",
        "title": "健康數據",
        "subs": [
          {
            "code": "Kiosk_Menu_Environment",
            "title": "設備環境設定​",
            "subs": null
          },
          {
            "code": "Kiosk_Menu_Facility",
            "title": "設備院舍設定",
            "subs": null
          },
          // {
          //   "code": "Kiosk_Menu_Installer",
          //   "title": "設備安裝包管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_Version",
          //   "title": "設備版本管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_Peripheral",
          //   "title": "設備外設綁定管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_Peripheral_Model",
          //   "title": "設備外設型號管理",
          //   "subs": null
          // },
          {
            "code": "Kiosk_Menu_Record",
            "title": "健康數據測量記錄",
            "subs": null
          },
          {
            "code": "Kiosk_Menu_Report",
            "title": "健康數據測量報告​",
            "subs": null
          },
          {
            "code": "Kiosk_Menu_Health_Data",
            "title": "會員健康數據",
            "subs": null
          },
          // {
          //   "code": "Kiosk_Menu_NFC_Manage",
          //   "title": "會員 NFC 管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_QrCode_Manage",
          //   "title": "會員 QrCode 管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_FaceID_Manage",
          //   "title": "會員 FaceID 管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_External_Manage",
          //   "title": "外部會員編號管理",
          //   "subs": null
          // },
          // {
          //   "code": "Kiosk_Menu_About_Us",
          //   "title": "關於頁面管理",
          //   "subs": null
          // },
        ]
      },
      {
        "code": "Mon_Menu",
        "title": "離床裝置",
        "subs": [
          {
            "code": "Mon_Menu_View",
            "title": "離床裝置信息總覽",
            "subs": null
          },
          {
            "code": "Mon_Menu_Environment",
            "title": "離床裝置環境設定",
            "subs": null
          },
          {
            "code": "Mon_Menu_Facility",
            "title": "離床裝置院舍設定",
            "subs": null
          },
          {
            "code": "Mon_Menu_Reset",
            "title": "離床裝置信息強制設定",
            "subs": null
          },
          {
            "code": "Mon_Menu_Report",
            "title": "離床裝置報告",
            "subs": null
          },
          {
            "code": "Mon_RawData_Download",
            "title": "原始數據下載",
            "subs": null
          }
        ]
      },
      {
        "code": "CallBell_Menu",
        "title": "緊急鍵",
        "subs": [
          {
            "code": "CallBell_Menu_Record",
            "title": "緊急鍵記錄",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Battery",
            "title": "緊急鍵電量",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Environment",
            "title": "緊急鍵環境設定",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Facility",
            "title": "緊急鍵院舍設定",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Report",
            "title": "緊急鍵報告",
            "subs": null
          },
          {
            "code": "CallBell_Menu_RawDown",
            "title": "緊急鍵原始數據下載",
            "subs": null
          },
          {
            "code": "CallBell_Menu_RawTech",
            "title": "緊急鍵原始數據下載(技術)",
            "subs": null
          },
          {
            "code": "CallBell_Menu_NFC_Manage",
            "title": "NFC標簽管理",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Patrol_Record",
            "title": "巡邏記錄",
            "subs": null
          },
          {
            "code": "CallBell_Menu_Patrol_Report",
            "title": "巡邏報告",
            "subs": null
          }
        ]
      },
      {
        "code": "GeoFencing_Menu",
        "title": "防遊走",
        "subs": [
          {
            "code": "GeoFencing_Menu_Record",
            "title": "遊走記錄",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_Environment",
            "title": "設備環境設定​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_Facility",
            "title": "設備院舍設定",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_Report",
            "title": "遊走報告​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_RawDown",
            "title": "遊走原始數據下載​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_Batch_Upload",
            "title": "設備批量上傳​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_RFID_Manage",
            "title": "RFID標簽管理​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_RFID_Batch",
            "title": "RFID批量上傳​",
            "subs": null
          },
          {
            "code": "GeoFencing_Menu_Reset",
            "title": "設備強制設定",
            "subs": null
          }
        ]
      },
      {
        "code": "Notice_Menu",
        "title": "通知中心",
        "subs": []
      },
      {
        "code": "Admin_Menu",
        "title": "管理員",
        "subs": []
      }
    ],
    "labels": []
  }
}

const GetProfile = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'profile': {
      'isActive': true,
      'phone': '1234567',
      'joinDate': '2000-02-28T00:00:00',
      'isAdmin': true,
      'roles': [
        {
          'companyID': 1,
          'companyName': '公司1',
          'facilityID': 1,
          'facilityName': '院舍1',
          'roleID': 1,
          'roleCode': 'SA',
          'roleName': '測試管理員',
          'roleCategory': 'SA',
        },
        {
          'companyID': 2,
          'companyName': '公司2',
          'facilityID': 2,
          'facilityName': '院舍2',
          'roleID': 1,
          'roleCode': 'SA',
          'roleName': '測試管理員',
          'roleCategory': 'SA',
        },
      ],
      'staffID': 1,
      'chineseName': 'test',
      'email': 'test@wehealth.com',
    },
  },
}

const GetStaffList = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'total': 3,
    'pageIndex': 0,
    'pageSize': 0,
    'staffs': [
      {
        'staffID': 1,
        'staffCode': '編號-1',
        'loginName': '名稱-1',
        'chineseName': '中文名-1',
        'englishName': 'english-1',
        'contactPhone': '12345678',
        'isActive': true,
      },
      {
        'staffID': 2,
        'staffCode': '編號-2',
        'loginName': '名稱-2',
        'chineseName': '中文名-2',
        'englishName': 'english-2',
        'contactPhone': '123456780',
        'isActive': true,
      },
      {
        'staffID': 3,
        'staffCode': '編號-3',
        'loginName': '名稱-3',
        'chineseName': '中文名-3',
        'englishName': 'english-3',
        'contactPhone': '123456789',
        'isActive': false,
      },
    ],
  },
}

const GetStaff = {
  'return_code': 0,
  'result_msg': 'Success',
  'data':
    {
      'staff':
        {
          'loginName': 'qinggu',
          'contactEmail': null,
          'contactPhone': null,
          'companyID': 1,
          'facilityRoleIDs': [
            {
              'facilityID': 24,
              'roleID': 21,
            },
            {
              'facilityID': 31,
              'roleID': 13,
            }],
          // 'loginNfcs': [
          //   'e1547ab7',
          //   'e1547ab6',
          // ],
          'facilityNfcs': [
            {
              'facilityID': 24,
              'facilityName': '常青树No.1院舍',
              'nfcID': 14,
              'nfcTag': 'FFDF0063721AD8DD',
            },
            {
              'facilityID': 24,
              'facilityName': '常青树No.2院舍',
              'nfcID': 30,
              'nfcTag': 'FFDF0063721EC0C1',
            },
            {
              'facilityID': 31,
              'nfcID': 31,
              'nfcTag': 'FFDF0063721EC0C2',
            }],
          'staffID': 32,
          'staffCode': '001',
          'chineseName': '顧晴',
          'englishName': 'QingGu',
          'isActive': true,
        },
    },
}

const AddStaff = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'staffID': 4,
  },
}

const UpdateStaff = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null,
}

const DeleteStaff = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null,
}

const EditStaffPassword = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null,
}

const GetPermissions = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "permissions": [
      {
        "code": "Gen_Login",
        "name": "登入"
      },
      {
        "code": "Gen_Forgot_PWD",
        "name": "忘記密碼"
      },
      {
        "code": "Gen_Update_PWD",
        "name": "更改密碼"
      },
      {
        "code": "Com_Menu",
        "name": "公司"
      },
      {
        "code": "Com_View",
        "name": "査詢公司"
      },
      {
        "code": "Com_List",
        "name": "公司清單"
      },
      {
        "code": "Com_Create",
        "name": "創建公司"
      },
      {
        "code": "Com_Edit",
        "name": "編輯公司"
      },
      {
        "code": "Com_Delete",
        "name": "删除公司"
      },
      {
        "code": "Fac_Menu",
        "name": "院舍"
      },
      {
        "code": "Fac_View",
        "name": "査詢院舍"
      },
      {
        "code": "Fac_List",
        "name": "院舍清單"
      },
      {
        "code": "Fac_Create",
        "name": "創建院舍"
      },
      {
        "code": "Fac_Edit",
        "name": "編輯院舍"
      },
      {
        "code": "Fac_Delete",
        "name": "删除院舍"
      },
      {
        "code": "Bed_Menu",
        "name": "床位"
      },
      {
        "code": "Bed_Type_Menu",
        "name": "床位類型"
      },
      {
        "code": "Bed_Floor_Menu",
        "name": "樓層與房間"
      },
      {
        "code": "Bed_Bed_Menu",
        "name": "床位"
      },
      {
        "code": "Bed_Type_View",
        "name": "査詢床位類型"
      },
      {
        "code": "Bed_Type_List",
        "name": "床位類型清單"
      },
      {
        "code": "Bed_Type_Create",
        "name": "創建床位類型"
      },
      {
        "code": "Bed_Type_Edit",
        "name": "編輯床位類型"
      },
      {
        "code": "Bed_Type_Delete",
        "name": "删除床位類型"
      },
      {
        "code": "Bed_Floor_View",
        "name": "査詢樓層"
      },
      {
        "code": "Bed_Floor_List",
        "name": "樓層清單"
      },
      {
        "code": "Bed_Floor_Create",
        "name": "創建樓層"
      },
      {
        "code": "Bed_Floor_Edit",
        "name": "編輯樓層"
      },
      {
        "code": "Bed_Floor_Delete",
        "name": "删除樓層"
      },
      {
        "code": "Bed_View",
        "name": "査詢床位"
      },
      {
        "code": "Bed_List",
        "name": "床位清單"
      },
      {
        "code": "Bed_Create",
        "name": "創建床位"
      },
      {
        "code": "Bed_Edit",
        "name": "編輯床位"
      },
      {
        "code": "Bed_Delete",
        "name": "删除床位"
      },
      {
        "code": "Sta_Menu",
        "name": "職員"
      },
      {
        "code": "Sta_View",
        "name": "査詢職員"
      },
      {
        "code": "Sta_List",
        "name": "職員名單"
      },
      {
        "code": "Sta_Create",
        "name": "創建職員"
      },
      {
        "code": "Sta_Edit",
        "name": "編輯職員"
      },
      {
        "code": "Sta_Delete",
        "name": "删除職員"
      },
      {
        "code": "Mem_Menu",
        "name": "會員"
      },
      {
        "code": "Mem_View",
        "name": "査詢會員"
      },
      {
        "code": "Mem_List",
        "name": "會員名單"
      },
      {
        "code": "Mem_Create",
        "name": "創建會員"
      },
      {
        "code": "Mem_Edit",
        "name": "編輯會員"
      },
      {
        "code": "Mem_Delete",
        "name": "删除會員"
      },
      {
        "code": "Gro_Menu",
        "name": "組別"
      },
      {
        "code": "Gro_View",
        "name": "査詢組別"
      },
      {
        "code": "Gro_List",
        "name": "組別清單"
      },
      {
        "code": "Gro_Create",
        "name": "創建組別"
      },
      {
        "code": "Gro_Edit",
        "name": "編輯組別"
      },
      {
        "code": "Gro_Delete",
        "name": "删除組別"
      },
      {
        "code": "Role_Menu",
        "name": "職位"
      },
      {
        "code": "Role_View",
        "name": "査詢職位"
      },
      {
        "code": "Role_List",
        "name": "職位清單"
      },
      {
        "code": "Role_Create",
        "name": "創建職位"
      },
      {
        "code": "Role_Edit",
        "name": "編輯職位"
      },
      {
        "code": "Role_Delete",
        "name": "删除職位"
      },
      {
        "code": "Mon_Menu",
        "name": "離床裝置"
      },
      {
        "code": "Mon_Menu_View",
        "name": "離床裝置信息總覽"
      },
      {
        "code": "Mon_Menu_Environment",
        "name": "離床裝置環境設定"
      },
      {
        "code": "Mon_Menu_Facility",
        "name": "離床裝置院舍設定"
      },
      {
        "code": "Mon_Menu_Reset",
        "name": "離床裝置信息強制設定"
      },
      {
        "code": "Mon_Menu_Report",
        "name": "離床裝置報告"
      },
      {
        "code": "Notice_Menu",
        "name": "通知中心"
      },
      {
        "code": "Notice_List",
        "name": "通知清單"
      },
      {
        "code": "Admin_Menu",
        "name": "管理員"
      },
      {
        "code": "Admin_View",
        "name": "査詢管理員"
      },
      {
        "code": "Admin_List",
        "name": "管理員名單"
      },
      {
        "code": "Admin_Create",
        "name": "創建管理員"
      },
      {
        "code": "Admin_Edit",
        "name": "編輯管理員"
      },
      {
        "code": "Admin_Delete",
        "name": "删除管理員"
      },
      {
        "code": "Admin_Impersonate",
        "name": "模擬職員"
      },
      {
        "code": "Mon_RawData_Download",
        "name": "原始數據下載"
      },
      {
        "code": "CallBell_Menu",
        "name": "緊急鍵"
      },
      {
        "code": "CallBell_Menu_Record",
        "name": "緊急鍵記錄"
      },
      {
        "code": "CallBell_Menu_Environment",
        "name": "緊急鍵環境設定"
      },
      {
        "code": "CallBell_Menu_Facility",
        "name": "緊急鍵院舍設定"
      },
      {
        "code": "CallBell_Menu_Report",
        "name": "緊急鍵報告"
      },
      {
        "code": "CallBell_Menu_RawDown",
        "name": "緊急鍵原始數據下載"
      },
      {
        "code": "CallBell_Menu_NFC_Manage",
        "name": "NFC標簽管理"
      },
      {
        "code": "CallBell_Menu_Patrol_Record",
        "name": "巡邏記錄"
      },
      {
        "code": "CallBell_Menu_Patrol_Report",
        "name": "巡邏報告"
      },
      {
        "code": "CallBell_Menu_RawTech",
        "name": "緊急鍵原始數據下載(技術)"
      },
      {
        "code": "Fac_Admin",
        "name": "管理院舍"
      },
      {
        "code": "CallBell_NFC_List",
        "name": "NFC清單"
      },
      {
        "code": "CallBell_NFC_Edit",
        "name": "編輯NFC"
      },
      {
        "code": "CallBell_NFC_Delete",
        "name": "删除NFC"
      },
      {
        "code": "GeoFencing_Menu",
        "name": "防遊走"
      },
      {
        "code": "GeoFencing_Menu_Record",
        "name": "遊走記錄"
      },
      {
        "code": "GeoFencing_Menu_Environment",
        "name": "設備環境設定​"
      },
      {
        "code": "GeoFencing_Menu_Facility",
        "name": "設備院舍設定"
      },
      {
        "code": "GeoFencing_Menu_Report",
        "name": "遊走報告​"
      },
      {
        "code": "GeoFencing_Menu_RawDown",
        "name": "遊走原始數據下載​"
      },
      {
        "code": "GeoFencing_Menu_Batch_Upload",
        "name": "設備批量上傳​"
      },
      {
        "code": "GeoFencing_Menu_RFID_Manage",
        "name": "RFID標簽管理​"
      },
      {
        "code": "GeoFencing_Menu_RFID_Batch",
        "name": "RFID批量上傳​"
      },
      {
        "code": "GeoFencing_RFID_List",
        "name": "RFID清單"
      },
      {
        "code": "GeoFencing_RFID_Edit",
        "name": "編輯RFID"
      },
      {
        "code": "GeoFencing_RFID_Delete",
        "name": "删除RFID"
      },
      {
        "code": "GeoFencing_Confirm_Record",
        "name": "確認記錄"
      },
      {
        "code": "GeoFencing_Menu_Reset",
        "name": "設備強制設定"
      },
      {
        "code": "CallBell_Menu_Battery",
        "name": "緊急鍵電量"
      },
      {
        "code": "Kiosk_Menu",
        "name": "健康數據"
      },
      {
        "code": "Kiosk_Menu_Record",
        "name": "健康數據測量記錄"
      },
      {
        "code": "Kiosk_Menu_Environment",
        "name": "設備環境設定​"
      },
      {
        "code": "Kiosk_Menu_Facility",
        "name": "設備院舍設定"
      },
      {
        "code": "Kiosk_Menu_Report",
        "name": "健康數據測量報告​"
      },
      {
        "code": "Kiosk_Menu_NFC_Manage",
        "name": "會員 NFC 管理"
      },
      {
        "code": "Kiosk_NFC_List",
        "name": "NFC清單"
      },
      {
        "code": "Kiosk_NFC_Edit",
        "name": "編輯NFC"
      },
      {
        "code": "Kiosk_NFC_Delete",
        "name": "删除NFC"
      },
      {
        "code": "Kiosk_Menu_QrCode_Manage",
        "name": "會員 QrCode 管理"
      },
      {
        "code": "Kiosk_QrCode_List",
        "name": "QrCode清單"
      },
      {
        "code": "Kiosk_QrCode_Edit",
        "name": "編輯QrCode"
      },
      {
        "code": "Kiosk_QrCode_Delete",
        "name": "删除QrCode"
      },
      {
        "code": "Kiosk_Menu_About_Us",
        "name": "關於頁面管理"
      },
      {
        "code": "Kiosk_Menu_Installer",
        "name": "安裝包管理"
      },
      {
        "code": "Kiosk_Menu_Version",
        "name": "版本管理"
      },
      {
        "code": "Kiosk_Menu_External_Manage",
        "name": "外部會員編號管理"
      },
      {
        "code": "Kiosk_External_List",
        "name": "外部會員編號清單"
      },
      {
        "code": "Kiosk_External_Edit",
        "name": "編輯外部會員編號"
      },
      {
        "code": "Kiosk_External_Delete",
        "name": "删除外部會員編號"
      },
      {
        "code": "Kiosk_Member_Batch",
        "name": "批量上傳​"
      },
      {
        "code": "Kiosk_Menu_FaceID_Manage",
        "name": "會員 FaceID 管理"
      },
      {
        "code": "Kiosk_FaceID_List",
        "name": "FaceID清單"
      },
      {
        "code": "Kiosk_FaceID_Edit",
        "name": "編輯FaceID"
      },
      {
        "code": "Kiosk_FaceID_Delete",
        "name": "删除FaceID"
      },
      {
        "code": "Kiosk_CollectionID_List",
        "name": "CollectionID清單"
      },
      {
        "code": "Kiosk_CollectionID_Edit",
        "name": "編輯CollectionID"
      },
      {
        "code": "Kiosk_CollectionID_Delete",
        "name": "删除CollectionID"
      },
      {
        "code": "Nursing_Menu",
        "name": "護理工作"
      },
      {
        "code": "Kiosk_Menu_Health_Data",
        "name": "會員健康數據"
      },
      {
        "code": "Kiosk_Menu_Health_DataDown",
        "name": "會員健康數據下載​"
      },
      {
        "code": "Kiosk_Menu_Health_List",
        "name": "會員健康數據列表"
      },
      {
        "code": "Kiosk_Menu_Peripheral",
        "name": "外設管理"
      },
      {
        "code": "Kiosk_Peripheral_List",
        "name": "外設清單"
      },
      {
        "code": "Kiosk_Peripheral_Edit",
        "name": "編輯外設"
      },
      {
        "code": "Kiosk_Peripheral_Delete",
        "name": "删除外設"
      },
      {
        "code": "Kiosk_Menu_Peripheral_Model",
        "name": "外設型號管理"
      },
      {
        "code": "Kiosk_Peripheral_Model_List",
        "name": "外設型號清單"
      },
      {
        "code": "Kiosk_Peripheral_Model_Edit",
        "name": "編輯型號外設"
      },
      {
        "code": "Kiosk_Peripheral_Model_Delete",
        "name": "删除型號外設"
      }
    ]
  }
}

const ChangePassword = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null,
}

const EditProfile = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null,
}

const GetNfcList = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'total': 1,
    'pageIndex': 0,
    'pageSize': 0,
    'nfcs': [
      {
        'nfcTag': '000200634D3B1721',
        'staffID': 2,
        'staffCode': 'VA001',
        'chineseName': '陈晓强',
        'englishName': 'Little Strong',
        'isActive': true,
      },
    ],
  },
}

export default {
  // 部署版本號（並非項目版本號），客戶端發現該版本號更新後會引導用戶重新登錄（邏輯寫在 App.vue 中）。
  deploy_version: 70,

  // 重新登錄時，若部署版本有更新，則會清除以下本地存儲項目
  localStorageItemsToBeRemovedWhileLogout: [
    'access_token',
    'token_expire_in',
    'user_profile',
    'module_label',
    'menu_items',
    'menu_name',
    'permission',
    'role',
    'is_impersonated',
    'admin_company_id',
    'admin_facility_id',
    'custom_configs',
    'deploy_version', // make this the last one
  ],

  // 是否从mock获取数据，mock即本地假数据（存儲在 /src/@fake-db 目录下）
  // is_request_mock: true,
  is_request_mock: process.env.VUE_APP_API_ENV === 'Mock',

  // 是否启用 SignalR
  is_signalr_enabled: true,

  // 是否检查已经添加至apiList，当为true时，需要调用的接口要加到 /src/backendApiList文件中
  check_backend_api: false,

  // table 每页显示多少条数据
  table_per_page: 20,
  table_per_page_dropdown: [10, 20, 50, 100],

  // 是否显示多语言
  show_change_i18n: false,

  // api 超时时间
  api_timeout: 30 * 1000,

  // api 文件下載 超时时间
  api_download_timeout: 60 * 1000,

  // 服务器菜单code与本项目菜单路由对应关系 | 接口獲取到的菜單code隱射到代碼的路由
  menu_map: {
    Com_Menu: { route: 'company' },
    Fac_Menu: { route: 'facility' },
    Sta_Menu: { route: 'staff' },
    Mem_Menu: { route: 'member' },
    Gro_Menu: { route: 'group' },
    Role_Menu: { route: 'role' },
    Notice_Menu: { route: 'notice' },
    Admin_Menu: { route: 'admin' },

    // Bed
    Bed_Menu: { route: '' },
    Bed_Floor_Menu: { route: 'bedfloor' },
    Bed_Type_Menu: { route: 'bedtype' },
    Bed_Bed_Menu: { route: 'bed' },

    // BedExitMonitor
    Mon_Menu: { route: '' },
    Mon_Menu_View: { route: 'bedexitview' },
    Mon_Menu_Environment: { route: 'bedexitenvironment' },
    Mon_Menu_Facility: { route: 'bedexitfacility' },
    Mon_Menu_Reset: { route: 'bedexitreset' },
    Mon_Menu_Report: { route: 'bedexitreport' },
    Mon_Batch_Upload: { route: 'bedexitbatchupload' },
    Mon_RawData_Download: { route: 'bedexitrawdata' },

    // CallBell
    CallBell_Menu: { route: '' },
    CallBell_Menu_Record: { route: 'callBellRecord' },
    CallBell_Menu_Environment: { route: 'callBellEnvironment' },
    CallBell_Menu_Facility: { route: 'callBellFacility' },
    CallBell_Menu_Report: { route: 'callBellReport' },
    CallBell_Menu_RawDown: { route: 'callBellRawDown' },
    CallBell_Menu_RawTech: { route: 'callBellRawTech' },
    CallBell_Menu_Batch_Upload: { route: 'callBellBatchUpload' },
    CallBell_Menu_NFC_Manage: { route: 'callBellNfcManagement' },
    CallBell_Menu_Patrol_Record: { route: 'callBellPatrolRecord' },
    CallBell_Menu_Patrol_Report: { route: 'callBellPatrolReport' },
    CallBell_Menu_Battery: { route: 'callBellBattery' },

    // GeoFencing
    GeoFencing_Menu_Record: { route: 'geoFencingRecord' },
    GeoFencing_Menu_Environment: { route: 'geoFencingAntennaEnvironment' },
    GeoFencing_Menu_Facility: { route: 'geoFencingAntennaFacility' },
    GeoFencing_Menu_RFID_Manage: { route: 'geoFencingRfidManage' },
    GeoFencing_Menu_Report: { route: 'geoFencingReport' },
    GeoFencing_Menu_RawDown: { route: 'geoFencingRawDown' },
    GeoFencing_Menu_Batch_Upload: { route: 'geoFencingAntennaBatchUpload' },
    GeoFencing_Menu_RFID_Batch: { route: 'geoFencingRfidBatchUpload' },
    GeoFencing_Menu_Reset: { route: 'geoFencingReset' },

    // Kiosk
    Nursing_Menu: { route: 'kioskNursingWork' },
    Kiosk_Menu_NursingWorkLog: { route: 'kioskNursingWorkLog' },
    Kiosk_Menu_NoNursingRecord: { route: 'kioskNoNursingRecord' },
    Kiosk_Menu_Record: { route: 'kioskRecord' },
    Kiosk_Menu_Health_Data: { route: 'kioskMemberHealthData' },
    Kiosk_Menu_Environment: { route: 'kioskEnvironment' },
    Kiosk_Menu_Facility: { route: 'kioskFacility' },
    Kiosk_Menu_Report: { route: 'kioskReport' },
    Kiosk_Menu_Installer: { route: 'kioskManagePackage' },
    Kiosk_Menu_Version: { route: 'kioskManageVersion' },
    Kiosk_Menu_About_Us: { route: 'kioskManageAboutUs' },
    Kiosk_Menu_NFC_Manage: { route: 'kioskMemberNfc' },
    Kiosk_Menu_QrCode_Manage: { route: 'kioskMemberQr' },
    Kiosk_Menu_FaceID_Manage: { route: 'kioskMemberFaceId' },
    Kiosk_Menu_External_Manage: { route: 'kioskMemberExternal' },
    Kiosk_Menu_Peripheral: { route: 'kioskPeripheral' },
    Kiosk_Menu_Peripheral_Model: { route: 'kioskPeripheralModel' },
  },
}
